// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
global.toastr = require("toastr")

require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
//= require jquery
//= require jquery3
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("@hotwired/turbo")

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// require("")
    // <script type="text/javascript" src="/js/popper.min.js"  ></script>
    // <script type="text/javascript" src="/js/bootstrap.min.js"  ></script>
    // <script type="text/javascript" src="/js/mdb.min.js"  ></script>
    // <script type="text/javascript" src="/js/jquery-ui.min.js"></script>
// import 'jquery-ujs';

//= require_tree . # will add html.sortable

// import "bootstrap"
// import "../stylesheets/application"
// import "../stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import "controllers"
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
